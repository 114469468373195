/* eslint-disable jsx-a11y/anchor-is-valid */
export default function App() {
    function handleSubmit(e) {
      console.log("You clicked submit.");
      window.open(
        "https://docs.google.com/forms/d/e/1FAIpQLSfcFh8cPcuSZqNjfNNqqDUfPYxZIa41DhZCcerr0MrI9VfjFA/viewform",
        "_blank",
        "noopener,noreferrer"
      );
    }

  return (
    <div className="bg-white">
      <header className="absolute inset-x-0 top-0">
        <nav
          aria-label="Global"
          className="flex items-center justify-between p-4 sm:p-6 lg:px-8"
        >
          <div className="flex lg:flex-1">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                alt="Company Logo"
                src={require("./logo2.png")}
                className="h-12 w-auto sm:h-16 mb-4"
              />
            </a>
          </div>
        </nav>
        <div className="fixed inset-0" />
      </header>

      <div className="relative isolate px-4 pt-10 sm:px-6 sm:pt-20 lg:px-8">
        <div
          aria-hidden="true"
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        >
          <div
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[20rem] sm:w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          />
        </div>
        <div className="flex flex-col items-center sm:flex-row sm:justify-between py-4 lg:py-5">
          <div className="text-center sm:text-left">
            <div className="pt-8 lg:pt-0 flex justify-center sm:justify-start mt-4">
              <img
                alt="Text Logo"
                className="h-12 sm:h-16"
                src={require("./text.png")}
              />
            </div>
            <h1 className="text-balance text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">
              Your own Clinic expert for seamless solution to all your clinic
              management tasks.
            </h1>
            <p className="pt-8 text-md leading-7 text-gray-600 sm:text-lg">
              We are developing a new practice management software tailored to
              the needs of medical professionals.
            </p>
            <h1 className=" pt-16 pb-2 text-balance text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:text-6xl">
              Coming Soon...
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Meanwhile, you can fill this google form to show your interest
            </p>
            <div className="mt-8 flex flex-col sm:flex-row justify-center items-center w-full gap-6">
              <button
                onClick={handleSubmit}
                className="md:mr-80 md:mb-16 rounded-md bg-[#2e3c8a] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Register Your Interest
              </button>
            </div>
          </div>
          <div className="pt-10 sm:pt-0">
            <img
              alt="Clinic Management"
              src={require("./photo.png")}
              className="h-64 w-auto sm:h-[500px] lg:h-[650px]"
            />
          </div>
        </div>
        <div
          aria-hidden="true"
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
        >
          <div
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
            className="relative left-[calc(50%+2rem)] aspect-[1155/678] w-[20rem] sm:w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
          />
        </div>
      </div>
    </div>
  );
}
